<template>
  <div>
    <h5 class="is-size-5 has-text-centered">
      {{ doc.number }}
    </h5>
    <a-card>
      <a-value
        field="customer.name"
        :label="$t('customers.ref.name')"
      />

      <a-value
        field="operator.name"
        :label="$t('operators.ref.name')"
      />

      <a-value
        field="vehicleType.name"
        :label="$t('vehicleTypes.ref.name')"
      />

      <a-value
        field="number"
        :label="$t('common.number')"
      />

      <template
        #form
      >
        <a-select
          field="customer"
          options="customers"
          null-option
          @change="updateCustomer"
        />

        <a-select
          v-if="selectedCustomer"
          field="operator"
          null-option
          :options="selectedCustomer.operators"
          @change="updateOperator"
        />

        <a-select
          v-if="selectedOperator"
          field="vehicleType"
          null-option
          :options="selectedOperator.vehicleTypes"
        />

        <a-input
          field="number"
          type="text"
          @change="validateVehicleNumber"
        />
      </template>
    </a-card>

    <!-- <a-card
        :editable="false"
      >
        <template
          v-slot:header-buttons
        >
          <b-icon
            icon="delete"
            @click.native="removeNodes"
          />
        </template>
      <vehicle-nodes
        :nodes="values(nodes)"
        multi-select
        @remove="removeNode"
      />
      </a-card> -->
  </div>
</template>

<script>
import { values } from 'lodash'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import formMixin from './formMixin'
import VehicleNodes from './Vehicle/VehicleNodes'

export default {
  components: {
    VehicleNodes
  },
  mixins: [formMixin],
  computed: {
    ...mapGetters({
      nodes: 'vehicles/currentVehicleNodes',
      customers: 'customers/data',
      operators: 'operators/data',
      vehicleTypes: 'vehicleTypes/items'
    }),
    selectedCustomer () {
      return this.doc.customer ? this.customers[this.doc.customer.id] : null
    },
    selectedOperator () {
      return this.doc.operator ? this.operators[this.doc.operator.id] : null
    }
  },
  async created () {
    if (this.doc.customer) {
      await this.$store.dispatch('customers/fetchById', this.doc.customer.id)
    }
    if (this.doc.operator) {
      await this.$store.dispatch('operators/fetchById', this.doc.operator.id)
    }
    // this.$store.dispatch('vehicleNodes/fetchById', this.doc.id)
    /* this.$store.dispatch('vehicleNodes/openDBChannel', { where: [['id', '==', this.doc.id]] }) */
  },
  methods: {
    values,
    async updateCustomer (value) {
      if (value) {
        await this.$store.dispatch('customers/fetchById', value.id)
        await this.$store.dispatch('currentDoc/set', {
          field: 'operator',
          value: null
        })
      }
    },
    async updateOperator (value) {
      if (value) {
        await this.$store.dispatch('operators/fetchById', value.id)
        await this.$store.dispatch('currentDoc/set', {
          field: 'vehicleType',
          value: null
        })
      }
    },
    validateVehicleNumber () {
      const otherCustomerVehicles = _.filter(this.$store.getters['vehicles/items'], vehicle => vehicle.customer.id === this.doc.customer.id && vehicle.id !== this.doc.id)
      if (_.find(otherCustomerVehicles, { number: this.doc.number })) {
        this.$buefy.toast.open({
          duration: 3000,
          message: `Vehicle number ${this.doc.number} already exists in ${this.doc.customer.name}!`,
          position: 'is-bottom',
          type: 'is-warning'
        })
      }
    },
    removeNodes () {
      _.forEach(this.nodes, node => {
        if (node.active) {
          this.removeNode(node)
        }
      })
    },
    removeNode (value) {
      let parent = (value && value.parent) ? _.assign({}, value.parent) : null
      _.forEach(this.nodes, child => {
        if (child.parent && child.parent.id === value.id) {
          Vue.set(child, 'parent', parent)
        }
      })

      this.$store.dispatch('vehicleNodes/set', {
        id: this.doc.id,
        items: this.nodes
      })

      // Vue.delete(this.nodes, value.id)

      this.$store.dispatch('vehicleNodes/delete', `${this.doc.id}.items.${value.id}`)
    }
  }
}
</script>
