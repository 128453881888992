<template>
  <div class="menu">
    <p class="menu-label">
      <span
        v-if="!multiSelect"
      >Select a parent node</span>
      <span
        v-else
      >Vehicle nodes</span>
    </p>
    <ul class="menu-list">
      <vehicle-node
        :node="rootNode"
        :disabled="multiSelect"
        :nodes="newNodes"
        @select="select"
        @remove="remove"
      />
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import VehicleNode from './VehicleNode'

export default {
  components: {
    VehicleNode
  },
  props: {
    value: {
      type: Object,
      required: false
    },
    nodes: {
      type: Array,
      default: []
    },
    multiSelect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rootNode: {
        id: null,
        name: 'Root node',
        active: false
      }
    }
  },
  computed: {
    newNodes () {
      return _.map(this.nodes, node => {
        Vue.set(node, 'active', false)
        return node
      })
    }
  },
  created () {
    this.resetNodes()
    if (this.value) {
      this.select(this.value)
    }
  },
  methods: {
    resetNodes () {
      _.forEach(this.newNodes, node => {
        Vue.set(node, 'active', false)
      })
    },
    select (value) {
      if (!this.multiSelect) {
        this.resetNodes()
      }

      if (value !== null) {
        let selected = _.find(this.newNodes, { id: value.id })
        if (selected) {
          selected.active = !selected.active
        }
      }
      this.$emit('input', value.id ? value : null)
    },
    remove (value) {
      this.$emit('remove', value)
    }
  }
}
</script>
